<template>
    <div class="l-padded rule-list-view">
        <div class="l-stack l-gap-3 rule-list-view__container">
            <h1 class="t-title l-inline l-spread l-center-v">
                {{ $t('title') }}

                <router-link :to="{ name: 'ruleTemplates' }">
                    <IconButton hover-color="rgba(255, 255, 255, 0.7)">
                        <AddIcon width="24" height="24" />
                    </IconButton>
                </router-link>
            </h1>

            <div class="filters-row">
                <SegmentButton
                    v-model="filterActivity"
                    :options="filterActivityOptions"
                />

                <BaseInput
                    :debounce="400"
                    :placeholder="$t('name')"
                    block
                    @input="filterName = $event"
                >
                    <template #icon>
                        <SearchIcon width="16" height="16" />
                    </template>
                </BaseInput>

                <BaseMultiselect
                    v-model="filterAssets"
                    :placeholder="$t('assets', [$t('shared.assets')])"
                    :options="trackers"
                    track-by="id"
                    :custom-label="option => option.asset_details.name"
                    multiple
                    block
                    @input="loadData"
                />

                <BaseMultiselect
                    v-model="filterLocations"
                    :placeholder="$t('locations')"
                    :options="locationsSortedByName"
                    track-by="id"
                    label="name"
                    multiple
                    block
                    @input="loadData"
                />

                <BaseMultiselect
                    v-model="filterTypes"
                    :placeholder="$t('types')"
                    :options="assetTypes"
                    track-by="id"
                    label="name"
                    multiple
                    block
                    @input="loadData"
                />
            </div>

            <transition-group name="rules-list" tag="div" class="rules-list">
                <NotificationDetailsCard
                    v-for="rule in rulesFiltered"
                    :key="rule.pk"
                    :rule="rule"
                    @click="$router.push(`/rules/${rule.pk}`)"
                    @copy="copyRule(rule)"
                    @delete="deleteRule(rule)"
                    @resolve="resolveRule(rule)"
                    @snooze="snoozeRule(rule, $event)"
                    @toggle="toggleRule(rule)"
                />
            </transition-group>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone'

import { httpHelper } from '@/utils'
import AddIcon from '../../icons/AddIcon'
import BaseInput from '../../redesigned/BaseInput'
import BaseMultiselect from '../../redesigned/BaseMultiselect'
import IconButton from '../../IconButton'
import NotificationDetailsCard from '../../redesigned/NotificationDetailsCard'
import SearchIcon from '../../icons/SearchIcon'
import SegmentButton from '../../redesigned/SegmentButton'

export default {
    name: 'RuleListView',
    components: {
        AddIcon,
        BaseInput,
        BaseMultiselect,
        IconButton,
        NotificationDetailsCard,
        SearchIcon,
        SegmentButton,
    },
    props: {
        queryAssets: {
            type: Array,
            default: () => [],
        },
        queryLocations: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            filterActivity: ['all'],
            filterActivityOptions: [
                { value: 'all', label: this.$t('all') },
                { value: 'active', label: this.$t('active') },
                { value: 'inactive', label: this.$t('inactive') },
            ],
            filterAssets: [],
            filterLocations: [],
            filterName: '',
            filterTypes: [],
            rules: [],
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes', 'trackers']),
        ...mapGetters('location', ['locationsSortedByName']),
        rulesFiltered() {
            let rules = this.rules

            if (this.filterActivity[0] === 'active') {
                const now = moment.now()
                rules = rules.filter(
                    item => item.active && moment(item.valid_after) <= now
                )
            }

            if (this.filterActivity[0] === 'inactive') {
                const now = moment.now()
                rules = rules.filter(
                    item => !item.active || moment(item.valid_after) > now
                )
            }

            if (this.filterName) {
                const name = this.filterName.toLowerCase()
                rules = rules.filter(item =>
                    item.name.toLowerCase().includes(name)
                )
            }

            return rules
        },
    },
    watch: {
        $route(to, from) {
            if (this.$el.parentElement) {
                this.$el.parentElement.scrollTop = 0
            }

            if (to.name === 'ruleList' && /rule(Create|Edit)/.test(from.name)) {
                this.loadData()
            }
        },
    },
    mounted() {
        this.filterAssets = this.trackers.filter(item =>
            this.queryAssets.includes(item.asset)
        )

        this.filterLocations = this.locationsSortedByName.filter(item =>
            this.queryLocations.includes(item.id)
        )

        this.loadData()
    },
    methods: {
        async loadData() {
            const assets = this.filterAssets.map(item => item.asset).join()
            const locations = this.filterLocations.map(item => item.id).join()
            const asset_types = this.filterTypes.map(item => item.id).join()
            const params = {
                ...(assets && { assets }),
                ...(locations && { locations }),
                ...(asset_types && { asset_types }),
            }

            const { data } = await httpHelper.get('/alertrules/', { params })

            this.rules = data.results.sort((a, b) => b.pk - a.pk)
        },
        copyRule(rule) {
            this.$router.push({
                name: 'ruleCreate',
                params: {
                    template: {
                        ...rule,
                        id: undefined,
                        name: `${rule.name} (${this.$t('copy')})`,
                        notification_channels: rule.notification_channels?.map(
                            channel => ({ ...channel, id: undefined })
                        ),
                        pk: undefined,
                        valid_after: undefined,
                    },
                },
            })
        },
        deleteRule(rule) {
            this.$modal.show('dialog', {
                title: this.$t('confirmDelete'),
                buttons: [
                    {
                        title: this.$t('confirmDeleteNo'),
                        handler: () => {
                            this.$modal.hide('dialog')
                        },
                    },
                    {
                        default: true,
                        title: this.$t('confirmDeleteYes'),
                        handler: async () => {
                            this.$modal.hide('dialog')
                            await httpHelper.drop(`/alertrules/${rule.pk}/`)
                            this.loadData()
                        },
                    },
                ],
            })
        },
        async resolveRule(rule) {
            try {
                await httpHelper.post(
                    `/alertrules/${rule.pk}/resolve-notifications`
                )
                this.$notify(this.$t('resolveSuccess'))
            } catch {
                this.$notify(this.$t('resolveFailure'))
            }
        },
        async snoozeRule(rule, time) {
            const payload = {
                ...rule,
                valid_after: new Date(Date.now() + time),
            }
            await httpHelper.put(`/alertrules/${rule.pk}/`, payload)
            await this.loadData()
        },
        async toggleRule(rule) {
            const payload = { ...rule, active: !rule.active }
            await httpHelper.put(`/alertrules/${rule.pk}/`, payload)
            await this.loadData()
        },
    },
}
</script>

<i18n>
{
    "en": {
        "active": "Active",
        "all": "All",
        "assets": "All {0}",
        "confirmDelete": "Are you sure you want to delete the rule?",
        "confirmDeleteNo": "No",
        "confirmDeleteYes": "Yes",
        "copy": "copy",
        "inactive": "Inactive",
        "locations": "All Locations",
        "name": "Name",
        "resolveFailure": "Failed to resolve notifications",
        "resolveSuccess": "Notifications resolved",
        "title": "Rules",
        "types": "All Types"
    },
    "de": {
        "active": "Aktiv",
        "all": "Alle",
        "assets": "Alle {0}",
        "confirmDelete": "Sind Sie sicher, dass Sie die Regel löschen wollen?",
        "confirmDeleteNo": "Nein",
        "confirmDeleteYes": "Ja",
        "copy": "Kopie",
        "inactive": "Inaktiv",
        "locations": "Alle Standorte",
        "name": "Name",
        "resolveFailure": "Benachrichtigungen konnten nicht aufgelöst werden",
        "resolveSuccess": "Benachrichtigungen aufgelöst",
        "title": "Regeln",
        "types": "Alle Typen"
    },
    "fr": {
        "active": "Actif",
        "all": "Tout",
        "assets": "Tous {0}",
        "confirmDelete": "Êtes-vous sûr de vouloir supprimer cette règle ?",
        "confirmDeleteNo": "Non",
        "confirmDeleteYes": "Oui",
        "copy": "copie",
        "inactive": "Inactif",
        "locations": "Tous les emplacements",
        "name": "Nom",
        "resolveFailure": "Notification non résolue",
        "resolveSuccess": "Notification résolue",
        "title": "Règles",
        "types": "Tous les types"
    },
    "it": {
        "active": "Active",
        "all": "All",
        "assets": "All {0}",
        "confirmDelete": "Sei sicuro di voler cancellare la regola?",
        "confirmDeleteNo": "No",
        "confirmDeleteYes": "Si",
        "copy": "copia",
        "inactive": "Inactive",
        "locations": "All Locations",
        "name": "Name",
        "resolveFailure": "Notifiche non risolte",
        "resolveSuccess": "Notifiche risolte",
        "title": "Regole",
        "types": "All Types"
    }
}
</i18n>

<style lang="scss" scoped>
.rule-list-view {
    &__container {
        margin: 20px auto;
        max-width: 900px;
    }

    h1 {
        font-size: 28px;
        font-weight: 700;
        color: $color-primary;

        svg {
            color: $color-accent;
        }
    }
}

.t-title {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.filters-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-right: -12px;
    margin-bottom: -12px;

    & > * {
        margin-right: 12px;
        margin-bottom: 12px;

        &:not(:first-child) {
            flex: 1 0 220px;
        }
    }
}

.rules-list {
    & > * {
        width: 100%;
        transition: all 0.2s;
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &-leave-active {
        position: absolute;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
